<template>
    <v-container fluid>
        <PageLayout>
            <template>
                <v-form @submit.prevent="getItems('search')">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly && can(['administrator','hr_manager'])" :to="{name: 'administrator.create'}" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <TabsNav />
                    <div class="d-flex justify-space-between mb-10">
                        <div class="d-flex align-center">
                            <h1>{{ filter_show ? $t('search_administrator') : $t('roles') }}</h1>
                        </div>
                        <div v-if="!$vuetify.breakpoint.xsOnly && can(['administrator','hr_manager'])" v-show="!filter_show" class="text-right">
                            <v-btn class="infinity_button" color="secondary" @click="openEditRoleDialog(null)">
                                {{ $t('create') }}
                            </v-btn>
                        </div>
                    </div>
                </v-form>

                <ResizableTable
                    class="main-data-table"
                    :columns="columns"
                    :rows="items"
                >
                    <template v-slot:header="{ props }">
                        <thead>
                        <tr>
                            <th
                                v-for="(column, index) in columns"
                                :key="index"
                            >
                                <div class="resizable-table__cell">
                                    {{ column.label }}
                                    <v-icon
                                        small
                                        v-if="column.prop.substring(0, 5) === 'role_'"
                                        @click="deleteRole(column.prop)"
                                    >
                                        mdi-trash-can-outline
                                    </v-icon>
                                    <v-icon
                                        small
                                        v-if="column.prop.substring(0, 5) === 'role_'"
                                        @click="openEditRoleDialog(column.prop.substring(5))"
                                    >
                                        mdi-pencil-outline
                                    </v-icon>
                                </div>
                            </th>
                        </tr>
                        </thead>
                    </template>
                    <template v-for="(column) in columns" v-slot:[column.prop]="{ item: permission }">
                        <template v-if="column.prop.substring(0, 5) === 'role_'">
                            <v-switch
                                v-model="permission[column.prop]"
                                @change="onChangePermission(column.prop.substring(5), permission.id, $event)"
                            ></v-switch>
                        </template>
                        <template v-else>
                            {{ permission[column.prop] }}
                        </template>
                    </template>
                </ResizableTable>

                <v-dialog v-model="role">
                    <v-card>
                        <v-card-title>
                            {{ $t('create_role') }}
                        </v-card-title>

                        <v-card-text>
                            <TextInput v-model="role.display_name" :label="$t('role.display_name')" id="role_name" />
                            <TextInput v-model="role.name" :label="$t('role.name')" id="role_slug" />
                            <TextInput v-model="role.description" :label="$t('role.description')" id="role_description"/>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <Btn @click="closeEditRoleDialog">{{ $t('cancel') }}</Btn>
                            <Btn v-if="this.role.id" @click="updateRole">{{ $t('role_update') }}</Btn>
                            <Btn v-else @click="addRole">{{ $t('role_create') }}</Btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </PageLayout>
    </v-container>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import {mapActions, mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "Permission",

    directives: {
        mask,
    },

    components: {
        Btn,
        TextInput,
        ResizableTable,
        TabsNav,
        // ValidationProvider,
        // ValidationObserver,
        PageLayout
    },

    data() {
        return {
            entityName: 'role',
            roles: [],
            permissions: [],
            editDialog: false,
            role: false,

            options: {
                ...FilterView.options,
                sortBy: ['id'],
                sortDir: [true],
            },

            fab: false,

            totalVisiblePag: 5,
            totalItems: 0,
            loading: false,
            filter_show: false,
        }
    },

    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask', 'formAdministrator']),

        phone: function () {
            return this.changePhone(this.phoneRaw)
        },

        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },

        items() {
            let items = [];

            for(let p in this.permissions) {
                let permission = this.permissions[p];
                let row = {
                    id: permission.id,
                    name: permission.name,
                };

                for(let r in this.roles) {
                    let role = this.roles[r];
                    row['role_' + role.name] = role.permissions.includes(permission.id);
                }

                items.push(row)
            }

            return items;
        },

        columns() {
            let cols = [];

            cols.push({
                active: true,
                label: this.$t('permission_name'),
                align: "left",
                sortable: true,
                prop: "name"
            });

            for(let r in this.roles) {
                let role = this.roles[r];

                cols.push({
                    active: true,
                    label: role.display_name,
                    align: "left",
                    prop: 'role_' + role.name
                })
            }

            return cols;
        }
    },

    mounted() {
        this.getItems()
        this.getPermissions()
    },

    methods: {
        ...mapActions(['setFormAdministrator']),

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
        },

        onChangePermission(roleName, permissionId) {
            let role = this.roles.find(r => r.name === roleName);
            let permission = this.permissions.find(r => r.id === permissionId);

            if(role.permissions.includes(permission.id)) {
                role.permissions = role.permissions.filter(p => p !== permission.id);
            } else {
                role.permissions.push(permission.id);
            }

            this.$http.put(`admin/role/${role.id}`, {
                permissions: role.permissions
            })
                .then(res => {
                    this.$toastr.success(this.$t('role_permissions_updated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('role_permissions_not_updated'))
                })
        },

        getItems() {
            this.getRoles();
            this.getPermissions();
        },

        async getRoles(type) {
            this.loading = true
            const {
                sortBy,
                sortDir,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }

            if (sortDir[0] !== undefined) {
                params.sortDir = sortDir[0] ? 'asc' : 'desc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.administrator) {
                params.administrator = this.administrator
            }

            if (this.phone) {
                params.phone = this.phone
            }

            if (this.email) {
                params.email = this.email
            }

            if (this.employee_position) {
                params.employee_position = this.employee_position
            }

            params.active = this.active

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            await this.$http
                .get("admin/role", {
                    params: params,
                })
                .then(res => {
                    this.roles = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalItems = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.roles = []
                    this.totalItems = 0
                    this.$toastr.error(this.$t('failed_to_get_list_roles'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        getPermissions() {
            this.loading = true
            this.$http.get('admin/permission', {
                query: {
                    lang: this.lang
                }
            })
                .then(({body}) => {
                    this.permissions = body
                    this.loading = false
                })
        },

        async getAdmins() {
            this.loading = true
            let params = {};
            params.active = this.active
            params.perPage = 1000

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.adminItems = res.body.data
                    this.filterData = res.body.filter
                })
                .catch(err => {
                    this.adminItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        addRole() {
            this.$http.post('admin/role', this.role)
                .then(({body}) => {
                    this.$toastr.success(this.$t('role_created'))
                    this.closeEditRoleDialog()
                    this.getRoles()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('role_not_created'))
                })
        },

        updateRole() {
            this.$http.put(`admin/role/${this.role.id}`, this.role)
                .then(({body}) => {
                    this.$toastr.success(this.$t('role_updated'))
                    this.closeEditRoleDialog()
                    this.getRoles()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('role_not_updated'))
                })
        },

        deleteRole(column) {
            if(confirm(this.$t('role_delete_confirmation'))) {
                let role = this.roles.find(r => r.name === column.substring(5));

                this.$http.delete(`admin/role/${role.id}`)
                    .then(({body}) => {
                        this.$toastr.success(this.$t('role_deleted'))
                        this.getRoles()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('role_not_deleted'))
                    })
            }
        },

        openEditRoleDialog(roleName = null) {
            if(roleName) {
                this.role = this.roles.find(r => r.name === roleName)
            } else {
                this.role = {
                    name: '',
                    display_name: '',
                    description: ''
                }
            }
        },

        closeEditRoleDialog() {
            this.role = false;
        }
    }
}
</script>
